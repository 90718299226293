import React from 'react';
// import ReactGA from 'react-ga';
import Plausible from 'plausible-tracker';

import { About, Footer, Header, Skills, Testimonial, Work } from './container';
import { Navbar } from './components';
import './App.scss';

Plausible({
  domain: 'richard-bathiebo.com',
});

const App = () => (
  <div className="app">
    <Navbar />
    <Header />
    <About />
    <Work />
    <Skills />
    <Testimonial />
    <Footer />

    {/* <script defer data-domain="richard-bathiebo.com" src="https://plausible.io/js/plausible.js" /> */}

  </div>
);

export default App;
