import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ReactTooltip from 'react-tooltip';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Skills.scss';

const oneWork = {
  _key: '6a45e8537e00',
  _type: 'workExperience',
  company: 'WATI',
  desc: 'desc',
  name: 'Mobile Developer',
};

const d = {
  _createdAt: '2022-02-23T05:18:01Z',
  _id: '79142f96-973d-40e6-b81c-d820445a34ea',
  _rev: 'x7LSS4V0YYXVOr1G2oajpm',
  _type: 'experiences',
  _updatedAt: '2022-02-23T05:20:45Z',
  works: [
    oneWork,
  ],
  year: 'May - July (2019)',
};

function getObj(year, company, desc = null, jobTitle = 'Fullstack Developer') {
  return { ...d, year, works: [{ ...oneWork, company, name: jobTitle, desc: desc || jobTitle }] };
}

let experiences = [
  getObj('February (2018) - May (2018)', 'Tendances Sunugal'),
  getObj('May (2019) - July (2019)', 'WATI'),
  getObj('May (2019) - July (2020)', 'CovidOut'),
  getObj('February (2021) - Now', 'Mayyan'),
];
experiences = experiences.reverse();

const Skills = () => {
  // const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';
    const skillsQuery = '*[_type == "skills"]';

    client.fetch(query).then((data) => {
      console.log('exper');
      console.log(data);
      // setExperiences(data);
    });

    client.fetch(skillsQuery).then((data) => {
      console.log('skills');
      console.log(data);

      setSkills(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">Skills & Experiences</h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.map((skill, i) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={skill.name + i}
            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
        <div className="app__skills-exp">
          {experiences.map((experience) => (
            <motion.div
              className="app__skills-exp-item"
              key={experience.year}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {experience.works.map((work) => (
                  <>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                      key={work.name}
                    >
                      <h4 className="bold-text">{work.name}</h4>
                      <p className="p-text">{work.company}</p>
                    </motion.div>
                    <ReactTooltip
                      id={work.name}
                      effect="solid"
                      arrowColor="#fff"
                      className="skills-tooltip"
                    >
                      {work.desc}
                    </ReactTooltip>
                  </>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

// VISIO Python - 6 developpeurs

// HERO.fr clean archi

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__whitebg',
);
